<template>
  <div :class="$style['footer']">
    <div :class="$style['button-wrap']">
      <template v-if="!hideActions">
        <base-button-dropdown
          v-if="
            mode === FooterModalType.edit &&
            (actions?.includes(FooterModalActionType.activation) ||
              actions?.includes(FooterModalActionType.deactivation))
          "
          :confirm-delete="!isPimProductsEditModal"
          :form-model="formModel"
          placement="top"
          @delete="deleteItem"
          @toggleActivation="toggleActivationItem"
        />
        <div
          v-if="
            mode === FooterModalType.edit &&
            actions?.length === 1 &&
            actions?.includes(FooterModalActionType.delete)
          "
        >
          <el-popconfirm
            v-if="hasDestroyAccess"
            cancel-button-text="Нет"
            confirm-button-text="Да"
            icon-color="red"
            title="Вы уверены, что хотите удалить?"
            width="300"
            @confirm="deleteItem"
          >
            <template #reference>
              <base-button
                v-if="
                  actions?.includes(FooterModalActionType.delete) &&
                  hasDestroyAccess
                "
                :declaration="{
                  id: 'delete-button',
                  attrs: {
                    type: 'danger',
                    plain: true,
                  },
                  text: 'Удалить',
                }"
              />
            </template>
          </el-popconfirm>
          <base-button
            v-if="
              actions.includes(FooterModalActionType.delete) &&
              !hasDestroyAccess
            "
            v-tooltip="{
              disabled: hasDestroyAccess,
              text: TOOLTIP_TEXT_RIGHTS.replace('__ACTION__', 'удаление'),
            }"
            :declaration="{
              id: 'delete-button-disabled',
              text: 'Удалить',
              attrs: {
                disabled: true,
                type: 'danger',
                plain: true,
              },
            }"
          />
        </div>
        <template
          v-else-if="
            isPimProductsEditModal &&
            actions?.includes(FooterModalActionType.restore)
          "
        >
          <base-button
            :class="{ [$style['button-disabled']]: !hasRestoreAccess }"
            :declaration="{
              text: 'Восстановить',
              attrs: {
                disabled: !hasRestoreAccess,
              },
            }"
            @click="emit('event', 'restore', { id: formModel.id })"
          />
        </template>
      </template>
      <router-link
        v-if="isDisplayLinkToLog"
        :to="{ name: 'actions', query: formModel.log }"
        custom
      >
        <a
          :class="$style['link-btn']"
          :href="logsLink.href.value"
          class="link"
          target="_blank"
        >
          <notebook :size="16" />
        </a>
      </router-link>
    </div>
    <div v-if="!hideActions" :class="$style['collection']">
      <base-checkbox
        v-if="mode === FooterModalType.create && hasStoreAccess"
        :declaration="{
          id: 'create-button',
          name: 'needMore',
          element: 'checkbox',
          model: 'needMore',
          attrs: {
            border: true,
            label: 'Создать еще',
          },
        }"
        :form-model="checkboxForm"
      />
      <template v-if="mode === FooterModalType.filter">
        <base-button
          :declaration="{
            id: 'clear-button',
            text: 'Очистить',
            attrs: {
              disabled: false,
              type: 'primary',
            },
          }"
          @click="onClearEvent"
        />
        <base-button
          :declaration="{
            id: 'hide-button',
            text: filterCountResults,
            type: 'primary',
            attrs: {
              disabled: !formModel.filterCountButtonSubmit,
              type: 'primary',
              loading: filterLoading,
            },
          }"
          @click="emit('close', formModel)"
        />
      </template>
      <base-button
        v-if="isServiceEditPage"
        :declaration="{
          id: 'update-button',
          text: isMobile ? 'Обновить' : 'Обновить подчиненные объекты',
          attrs: {
            type: 'primary',
          },
        }"
        @click="fillSystemObjects"
      />
      <base-button
        v-if="
          mode !== FooterModalType.filter && (hasStoreAccess || hasUpdateAccess)
        "
        :declaration="{
          id: 'save-button',
          text: defaultText,
          attrs: {
            type: 'primary',
            disabled:
              mode === FooterModalType.create
                ? !hasStoreAccess
                : !hasUpdateAccess,
          },
        }"
        @click="submitForm"
      />
      <base-button
        v-if="!hasStoreAccess && !hasUpdateAccess"
        :declaration="{
          id: 'close-button',
          text: 'Закрыть',
          attrs: {
            type: 'primary',
          },
        }"
        @click="hideModal"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onUnmounted, ref, toRefs } from "vue";
import BaseButton from "@/components/base/button/BaseButton.vue";
import BaseButtonDropdown from "@/components/base/button/BaseButtonDropdown.vue";
import BaseCheckbox from "@/components/base/BaseCheckbox.vue";
import { RouteAccess } from "@/router/types";
import { useLink, useRouter } from "vue-router";
import { stringDeclension } from "@/helpers/dictionary";
import vTooltip from "@/directives/vTooltip";
import { TOOLTIP_TEXT_RIGHTS } from "@/dictionary/tooltip";
import { NOTHING_FOUND } from "@/dictionary/response";
import useAdaptive from "@/composables/hooks/useAdaptive";
import useAccessRights from "@/composables/hooks/useAccessRights";
import { FormModel } from "@/plugins/form-generator-json-v2/types";
import { ServiceName } from "@/constants";
import {
  FooterModalActionType,
  FooterModalType,
} from "@/components/footer/constants";
import { user } from "@/model/user";
import { Notebook } from "@element-plus/icons-vue";

const { isMobile } = useAdaptive();

const emit = defineEmits([
  "delete",
  "toggleActivation",
  "fillSystemObjects",
  "hideModal",
  "submit",
  "close",
  "clear",
  "restore",
  "event",
]);

interface Props {
  mode: FooterModalType;
  defaultText: string;
  formModel: FormModel & {
    name?: string;
    filterCountButtonSubmit?: number;
    active?: boolean;
    is_active?: boolean;
  };
  actions?: FooterModalActionType[];
  isServiceEditPage?: boolean;
  filterLoading?: boolean;
  hideActions?: boolean;
}

const props = defineProps<Props>();
const { formModel } = toRefs(props);

const router = useRouter();
const { service: accessService, object: accessObject } =
  (router.currentRoute.value?.meta?.access as RouteAccess) || {};
const { hasUpdateAccess, hasDestroyAccess, hasStoreAccess, hasRestoreAccess } =
  useAccessRights(router);

const checkboxForm = ref<any>({ needMore: false });

const logsLink = computed(() => {
  if (formModel?.value?.log) {
    return useLink({
      ...props,
      to: { name: "actions", query: formModel.value.log },
    });
  }
  return useLink({
    ...props,
    to: { name: "actions" },
  });
});

const hasAccessToLogs = computed<boolean>(() =>
  logsLink.value?.route.value
    ? user.checkAccess(logsLink.value.route.value)
    : false
);

const isDisplayLinkToLog = computed<boolean>(
  () =>
    !!(
      logsLink.value?.href.value &&
      hasAccessToLogs.value &&
      props.mode === FooterModalType.edit
    )
);

onUnmounted(() => (checkboxForm.value.needMore = false));

const submitForm = () => {
  emit(
    "submit",
    !checkboxForm.value.needMore,
    checkboxForm.value.needMore,
    checkboxForm.value.needMore
  );
};

const filterCountResults = computed((): string => {
  if (!formModel.value.filterCountButtonSubmit) {
    return NOTHING_FOUND;
  }

  const resultsWord = stringDeclension(
    formModel.value.filterCountButtonSubmit,
    ["результат", "результата", "результатов"]
  );

  return `Показать ${formModel.value.filterCountButtonSubmit} ${resultsWord}`;
});

const isPimProductsEditModal = computed(
  (): boolean =>
    props.mode === FooterModalType.edit &&
    accessService === ServiceName.pim &&
    accessObject === "products"
);

const actions = computed((): FooterModalActionType[] => {
  const actions = [...(props.actions || [])];

  if (formModel.value?.deleted_at) {
    return [FooterModalActionType.restore];
  }

  if (
    !actions.includes(FooterModalActionType.activation) &&
    !actions.includes(FooterModalActionType.deactivation)
  ) {
    if (formModel.value && Object.hasOwn(formModel.value, "active")) {
      actions.push(
        formModel.value?.active
          ? FooterModalActionType.deactivation
          : FooterModalActionType.activation
      );
    } else if (formModel.value && Object.hasOwn(formModel.value, "is_active")) {
      actions.push(
        formModel.value?.is_active
          ? FooterModalActionType.deactivation
          : FooterModalActionType.activation
      );
    }
  }

  return actions;
});

const deleteItem = () => {
  emit("delete");
  emit("hideModal");
};

const toggleActivationItem = () => {
  emit("toggleActivation");
  emit("hideModal");
};

const hideModal = () => {
  emit("hideModal");
};

const fillSystemObjects = () => emit("fillSystemObjects");

const onClearEvent = () => {
  emit("event", "onClear");
  emit("clear", formModel);
};
</script>

<style lang="scss" module>
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .collection {
    display: flex;
    align-items: center;
    gap: 15px;

    & > div,
    label {
      height: 40px;
    }
  }
}

.button-disabled {
  background: var(--el-fill-color-light);

  &:hover {
    background: var(--el-fill-color-light);
  }
}

.button-wrap {
  display: flex;
}

.link-btn {
  display: block;
  height: 40px;
  width: 56px;
  padding: 12px 20px;
  border: 1px solid var(--el-border-color);
  border-radius: var(--el-border-radius-base);
  font-size: var(--el-font-size-base);
  background-color: var(--el-fill-color-blank);
  margin-left: 12px;

  &:hover {
    background-color: var(--el-color-primary-light-9);
    border-color: var(--el-color-success-light-7);
    color: var(--el-color-primary);
    outline: none;
  }
}
</style>
