import {
  GeneratorDeclaration,
  PresetValues,
} from "@/plugins/form-generator-json-v2/types";
import { computed, ComputedRef, Ref } from "vue";
import { ElLoading } from "element-plus";
import getRelationship from "@/helpers/getRelationship";
import { user } from "@/model/user";
import { useWheels } from "@/composables/hooks/useWheels";
import { Command } from "@/components/working-sections/default/components/actions/constants";
import getUsersFromLinked from "@/helpers/getUsersFromLinked";
import useSectionModal from "@/composables/hooks/modals/useSectionModal";
import { QUERY_PARAM } from "@/components/config";
import { Service } from "@/api/core/service";
import { Router } from "vue-router";
import { GetParametersArgumentsDeclaration } from "@/api/core/types";
import { DataList } from "@/components/types";
import useSelection from "@/composables/hooks/useSelection";
import {
  TableColumn,
  WorkingSectionsDefaultInterface,
} from "@/model/working-sections/types";
import { ServiceName } from "@/constants";

export default (
  isWizard: boolean,
  service: InstanceType<typeof Service>,
  defaultSectionDeclaration: WorkingSectionsDefaultInterface,
  state: Record<string, any>,
  isCopy: Ref<boolean>,
  presetValues: PresetValues,
  updatedRowId: Ref<number | string>,
  editForm: GeneratorDeclaration,
  dataList: DataList,
  setPaginationData: (value: any) => void,
  dataPayload: ComputedRef<GetParametersArgumentsDeclaration>,
  accessObject: string,
  accessService: string,
  serviceForm: GeneratorDeclaration,
  router: Router,
  presetData?: ComputedRef<Record<string, any>> | null,
  getDefaultFilters?: () => Record<string, string>
): {
  getData: () => Promise<any>;
  editRow: (
    id: number | string,
    options?: {
      copy?: boolean;
      copyWithDependencies?: boolean;
    }
  ) => Promise<void>;
  toggleActivation: (
    id: string | number,
    active: boolean,
    data?: Record<string, any>
  ) => Promise<void>;
} => {
  const { getWheelsPresetValues, getWheelsToggledData } = useWheels();
  const { show: showModal } = useSectionModal(QUERY_PARAM);
  const { selection, setSelection } = useSelection();
  const logServiceName: ComputedRef<string | null> = computed(
    () =>
      defaultSectionDeclaration?.forms.service?.settings?.logServiceName || null
  );
  const isWheels = editForm.form.name === "wheels";

  const getRowData = async (id: number | string) => {
    if (!service) {
      return;
    }
    try {
      const res = await service.item(id);
      return res.data;
    } catch (e) {
      router.replace({
        ...router.currentRoute.value,
      });
      console.error(e);
    }
  };

  const editRow = async (
    id: number | string,
    options?: {
      copy?: boolean;
      copyWithDependencies?: boolean;
    }
  ) => {
    const loading = ElLoading.service({
      lock: true,
    });

    const rowData = await getRowData(id);
    if (!rowData) {
      loading.close();
      return;
    }

    if (!options?.copy && !options?.copyWithDependencies) {
      state.action = Command.EDIT;
    } else if (options.copy && !options.copyWithDependencies) {
      state.action = Command.COPY;
    } else {
      state.action = Command.COPYWITHDEPENDENCIES;
    }

    isCopy.value = !!options?.copy;

    if (options?.copy && !options?.copyWithDependencies) {
      updatedRowId.value = 0;
    } else {
      updatedRowId.value = id;
    }
    let formModel = {} as any;

    if (!isWizard && !options?.copy) {
      formModel.id = id;
    }

    if (isWizard) {
      (editForm.items || []).forEach((item: any) => {
        formModel[item.model] = item.include
          ? getRelationship(item.include, rowData)
          : rowData.attributes[item.model];
      });
    } else {
      (editForm.items || []).forEach((item: any) => {
        if (item.extractedFrom) {
          const relationships = getRelationship(item.extractedFrom, rowData);
          formModel[item.model] = "";

          if (relationships.length) {
            const relationship = relationships.find(
              (attr: any) => attr.attributes.type === item.model
            );

            formModel[item.model] = relationship
              ? relationship.attributes.data
              : "";

            if (
              relationship?.attributes.confirmed !== undefined &&
              relationship?.attributes.confirmed === 0 &&
              user.data.profile.admin
            ) {
              formModel[`${item.model}Id`] = relationship.id;
            }
          }
          return;
        }

        if (item.propertiesViewMode) {
          formModel[item.model] = rowData;
        } else {
          formModel[item.model] = item.include
            ? getRelationship(item.include, rowData)
            : rowData.attributes[item.model];
        }

        const key = defaultSectionDeclaration.cols[item.model]?.relData;
        if (key) {
          const relDataFunction =
            defaultSectionDeclaration.cols[item.model]?.type;
          const relDataKey =
            defaultSectionDeclaration.cols[item.model]?.relDataKey;

          formModel[item.model] =
            typeof relDataFunction === "function"
              ? relDataFunction(rowData)
              : rowData?.relationships[key]?.attributes?.[relDataKey] ||
                rowData?.relationships[key]?.attributes?.name;
        }

        if (item.model === "wsBody")
          formModel[item.model] =
            rowData.relationships[item.model].attributes.name;
      });
    }

    presetValues.edit = !isWizard
      ? formModel
      : {
          id,
          ...formModel,
          ...(presetData ? presetData.value : {}),
        };
    formModel = {};
    (serviceForm.items || []).forEach((item: any) => {
      formModel[item.model] = item.include
        ? getRelationship(item.include, rowData)
        : rowData.attributes[item.model];
    });
    presetValues.service = formModel;
    presetValues.log = {
      "filter[object_id]": rowData.id,
      "filter[object_type]": rowData.type,
    };

    if (!isWizard) {
      if (isWheels && presetValues.edit) {
        presetValues.edit = {
          ...presetValues.edit,
          ...getWheelsPresetValues({
            presetData: presetValues.edit,
            data: rowData,
          }),
        };
      }

      if (logServiceName.value) {
        presetValues.log["filter[service]"] = logServiceName.value;
      }
    }

    if (accessObject === "roles" && presetValues.edit.users) {
      presetValues.edit.users = getUsersFromLinked(presetValues.edit.users);
    }

    loading.close();
    showModal(options?.copy ? "create" : updatedRowId.value.toString());
  };

  const toggleActivation = async (
    id: number | string,
    active: boolean,
    data?: Record<string, any>
  ) => {
    if (!service) {
      return;
    }

    let params: any = { active };

    if (data && "is_active" in data) {
      params = {
        is_active: active,
      };
    }

    if (accessService === ServiceName.landingsCms) {
      const landingParams = dataList.value.find(
        (item: TableColumn) => item.id === id
      );

      params.is_active = +active;
      params.name = landingParams?.attributes.name;
      params.frame = landingParams?.attributes.frame;
      params.position = landingParams?.attributes.position;
    } else if (
      accessService === ServiceName.pim &&
      accessObject === "products" &&
      data
    ) {
      const rowData = await getRowData(id);
      const properties = rowData.relationships.properties.map(
        (property: Record<string, any>) => {
          const { property_id, value, manual_filled } = property.attributes;
          return {
            property_id,
            value,
            manual_filled,
          };
        }
      );

      params = {
        ...data,
        is_active: +active,
        properties,
      };
    }

    if (isWheels && data) {
      params = getWheelsToggledData(active, data);
    }

    if (
      accessService === ServiceName.communication &&
      accessObject === "templates"
    ) {
      params = {
        is_active: +active,
        is_communication_template: true,
      };
    }

    try {
      await service.update(id, { ...params });
      if (selection.value.length) {
        setSelection([]);
      }
      await getData();
    } catch (e) {
      console.error(e);
    }
  };

  const getData = async () => {
    if (!service || dataList.loading) {
      return;
    }
    setPaginationData({ loading: true });
    dataList.loading = true;
    let meta = {
      current_page: 1,
      last_page: 1,
      total: 0,
    };

    let additionalPayload: Record<string, string> = {};
    //TODO: Не понятно, зачем оно тут, ломает фильтрацию. Выяснить и либо убрать, либо исправить.

    if (isWizard && getDefaultFilters) {
      additionalPayload = getDefaultFilters();
    }

    try {
      const res = await service.list({
        ...dataPayload.value,
        ...additionalPayload,
      });

      dataList.value = res.data;

      if (dataList.value) {
        if (isWizard) {
          dataList.value.forEach((item: any) => {
            const { all_attributes, current_attributes } =
              item.count_relationships;
            item.count_relationships = {
              attributes_status:
                item.type === "modifications"
                  ? item.count_relationships.wheels > 0
                  : all_attributes === current_attributes,
              attributes_text:
                item.type === "modifications"
                  ? null
                  : `${current_attributes} из ${all_attributes}`,
              binding: +(item.count_relationships.wrong_bindings > 0),
            };
          });
        } else if (accessObject === "roles") {
          dataList.value.forEach((item: any) => {
            item.relationships.users = getUsersFromLinked(
              item.relationships?.linkedUsers
            );
          });
        }
      }

      res.meta && (meta = res.meta);
      return res;
    } catch (e) {
      console.error(e);
    } finally {
      setPaginationData({
        ...meta,
        loading: false,
      });
      dataList.loading = false;
    }
  };

  return {
    getData,
    editRow,
    toggleActivation,
  };
};
