//todo @urazlo #refactor переделать файл из объекта на константы с типом RegExp. Добавить отдельный модуль для работы с временем/датами

const regex = {
  name_en: "[a-zA-Z0-9 _-]*",
  uuid: "[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}",
  landing_sort: "[1-9][0-9]*",
  tel_number:
    "(\\+7|8)[\\s(]?(\\d{3})[\\s)]?(\\d{3})[\\s-]?(\\d{2})[\\s-]?(\\d{2})",
  "YYYY-MM-DD": "^(\\d{4})\\-(0[1-9]|1[012])\\-(0[1-9]|[12][0-9]|3[01])",
  thousand_formatter: /\B(?=(\d{3})+(?!\d))/g,
  thousand_parser: /\$\s?|( *)/g,
  plate: "[а-яА-Я]{1} [0-9]{3} [а-яА-Я]{2} [0-9]{3}",
  like: "%[0-9a-zA-Z-@/.]*%",
  number: "/[^0-9]/g",
};

export const getRegex = (name: keyof typeof regex): string | RegExp => {
  return regex[name];
};
