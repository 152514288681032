import {
  VehicleType,
  vehicleNameByTypeMap,
} from "@/declarations/nipple/operations/form-elements/constants";

export default (data: Record<string, any>): string =>
  `${data.name} ${data?.radius ? data.radius + "R" : ""} ${
    data?.vehicle_type
      ? "для " + vehicleNameByTypeMap[data.vehicle_type as VehicleType]
      : ""
  }`;
