const words: Record<string, string> = {
  id: "Id",
  _id: "Id",
  name: "Наименование",
  domain: "Домен",
  description: "Описание",
  note_text: "Текст в сноске",
  stores: "Магазины",
  cities: "Города",
  regions: "Регионы",
  description_with_business: "Описание при наличии своего бизнеса",
  title: "Название",
  heading: "Заголовок",
  subtitle: "Описание",
  value: "Значение",
  value_with_business: "Значение при наличии своего бизнеса",
  button_text: "Текст на кнопке",
  button_url: "Ссылка на кнопке",
  url: "Ссылка",
  code: "Код",
  object: "Объект",
  created_at: "Дата создания",
  updated_at: "Дата изменения",
  service: "Сервис",
  admin: "Администратор",
  active: "Активность",
  enabled: "Активность",
  is_active: "Активность",
  is_hidden: "Скрыть раздел",
  surname: "Фамилия",
  position: "Должность",
  company: "Компания",
  text: "Текст",
  frame: "Идентификатор видео",
  video_description: "Описание видео",
  phone: "Телефон",
  investment_amount: "Размер инвестиций",
  lump_sum: "Паушальный взнос",
  royalty: "Роялти",
  room_area: "Площадь помещения",
  investment_amount_with_business:
    "Размер инвестиций при наличии своего бизнеса",
  lump_sum_with_business: "Паушальный взнос при наличии своего бизнеса",
  royalty_with_business: "Роялти при наличии своего бизнеса",
  room_area_with_business: "Площадь помещения при наличии своего бизнеса",
  service_area: "Сервисная площадь",
  project_payback: "Окупаемость проекта",
  retail_units: "Розничные точки",
  franchise_units: "Франшизные точки",
  launching_units: "Точки на стадии запуска",
  network_cities: "Города",
  status: "Статус",
  status_program: "Статус документа",
  work_schedule_start: "Начало работы",
  work_schedule_end: "Окончание работы",
  legal_address: "Юридический адрес",
  dadata_hid: "Внутренний идентификатор dadata",
  keywords: "Ключевые слова для быстрого поиска",
  salary: "Зарплата",
  profession_level: "Уровень профессионализма",
  link: "Ссылка",
  slots_interval: "Интервал оказания услуг",
  storage_area: "Площадь склада (м2)",
  stock_type: "Тип склада",
  kladr_id: "Идентификатор КЛАДР",
  fias_id: "Идентификатор ФИАС",
  country: "Страна",
  federal_district: "Федеральный округ",
  region: "Область",
  district: "Район",
  city: "Город",
  full_address: "Полный фактический адрес",
  metro: "Ближайшее метро",
  geo_lat: "Координаты: широта",
  geo_lon: "Координаты: долгота",
  iso: "ISO-код",
  fias_level: "Уровень по ФИАС",
  okato_code: "Код ОКАТО",
  oktmo_code: "Код ОКТМО",
  ifns_code: "Код ИФНС",
  cdek: "CДЭК",
  boxberry: "Boxberry",
  dpd: "DPD",
  time_zone: "Часовой пояс",
  employee_count: "Количество сотрудников",
  store_count: "Количество магазинов",
  city_count: "Количество городов",
  region_count: "Количество регионов",
  vacancy_id: "Идентификатор вакансии",
  feedback_full_name: "ФИО",
  employee_full_name: "ФИО",
  form_script: "Скрипт для формы",
  head_script: "Скрипт внутри тега head",
  body_script: "Скрипт перед закрывающим тегом body",
  seo_title: "SEO Заголовок",
  seo_description: "SEO Описание",
  email: "E-mail",
  groups: "Группы",
  bitrix_id: "bitrix ID",
  name_en: "Наименование (EN)",
  slug: "Внешний код (оригинальный)",
  external_code: "Внешний код",
  model: "Модель",
  year_from: "Год начала выпуска",
  year_to: "Год окончания выпуска",
  year_ranges: "Диапазоны годов выпуска",
  abbr: "Аббревиатура",
  brand: "Марка",
  generation: "Поколение",
  engine_code: "Код двигателя",
  engine_fuel: "Топливо",
  engine_power: "Мощность двигателя (л.с.)",
  engine_type: "Тип двигателя",
  engine_capacity: "Объем двигателя",
  year_from_to: "Года выпуска",
  years: "Года выпуска",
  type: "Тип",
  rim_width: "Диск - ширина",
  rim_diameter: "Диск - диаметр",
  rim_offset: "Диск - вылет (ET)",
  rim_size: "Размер диска",
  tire_width: "Шина - ширина",
  tire_diameter: "Шина - диаметр",
  tire_aspect_ratio: "Шина - высота профиля",
  tire_construction: "Шина - строение корда",
  tire_pressure: "Шина - давление (bar)",
  tire_section_width: "Шина - ширина секции",
  tire_size: "Размер шины",
  list_rim_width: "Ширина",
  list_rim_diameter: "Диаметр",
  list_rim_offset: "Вылет",
  list_rim_size: "Размер диска",
  list_tire_width: "Ширина",
  list_tire_diameter: "Диаметр",
  list_tire_aspect_ratio: "Высота профиля",
  list_tire_construction: "Строение корда",
  list_tire_pressure: "Давление (bar)",
  list_tire_section_width: "Ширина секции",
  list_tire_sizing_system: "Система калибровки",
  speed_index: "Индекс скорости",
  object_type: "Тип объекта",
  object_id: "ID объекта",
  command: "Команда",
  author_brinex_id: "Пользователь",
  log_created_at: "Дата",
  action: "Метод",
  data: "Данные",
  headers: "Заголовки",
  user_profile: "Профиль",
  modification: "Модификация",
  no_name: "Без имени",
  prefix_code: "Код с префиксом",
  tire: "Шина",
  disk: "Диск",
  axis: "Ось",
  stud_holes: "Количество болтов",
  production_year_from: "Год начала производства",
  production_year_to: "Год окончания производства",
  pcd: "Диаметр окружности, на которой расположены крепежные отверстия",
  dia: "Диаметр центрального отверстия литого диска",
  country_codes: "Коды стран",
  lock_thread_size: "Размер резьбы",
  bolt_pattern: "Расположение болтов",
  load_index: "Индекс нагрузки",
  assignment: "Назначение",
  sort: "Сортировка",
  category: "Вид",
  date_last_authorization: "Дата авторизации",
  tokens_update: "Обновления токена",
  letter_code: "Буквенный код",
  last_name: "Фамилия",
  second_name: "Отчество",
  contacts: "Контакты",
  price_category: "Ценовая категория",
  tire_sizing_system: "Шина - система калибровки",
  is_stock: "Комплектация",
  bodies: "Кузова",
  lock_type: "Крепеж колес",
  logo: "Логотип",
  countries: "Коды стран",
  front: "Передня ось (только для задней оси)",
  wheels_active: "Активность колёс",
  wheels_tire_diameter: "Шина и Диск - ширина",
  brand_id: "Марка",
  vehicle_type_id: "Тип",
  vehicle_category_id: "Вид",
  model_id: "Модель",
  generation_id: "Поколение",
  wheels_rim_offset: "Диск - вылет",
  wheels_tire_width: "Шина - ширина",
  wheels_tire_aspect_ratio: "Шина - высота профиля",
  wheels_rim_width: "Диск - ширина",
  modification_id: "Модификация",
  binding: "Связи",
  attributes: "Атрибуты",
  original_name: "Оригинальное название",
  body_number: "Номер кузова",
  trashed: "Архив",
  rim: "Диск",
  body: "Каталог",
  wsBody: "WS",
  body_id: "Каталог",
  ws_body_id: "WS",
  filter_the_rear: "Исключить из поиска заднее колесо",
  rear_tire_width: "Заднее колесо: шина - ширина",
  rear_tire_aspect_ratio: "Заднее колесо: шина - высота профиля",
  rear_rim_width: "Заднее колесо: диск - ширина",
  rear_rim_offset: "Заднее колесо: диск - вылет",
  rear_rim_aspect_ratio: "Заднее колесо: диск - высота профиля",
  rear_tire_diameter: "Заднее колесо: шина и Диск - ширина",
  rear_load_index: "Заднее колесо: индекс нагрузки",
  rear_speed_index: "Заднее колесо: индекс скорости",
  metric: "Метрическая",
  flotation: "Флотационная",
  lt_metric: "Легкогрузовая метрическая",
  lt_numeric: "Легкогрузовая числовая",
  users: "Пользователи",
  master_id: "Мастер ID",
  is_deleted: "Удален",
  number: "Номер",
  date: "Дата",
  organization: "Организация",
  is_canceled: "Аннулирован",
  start_date: "Дата начала",
  finish_date: "Дата окончания",
  validity: "Срок действия",
  main_contract_id: "Основной договор",
  main_contract_master_id: "Мастер ID основного договора",
  prolongation_date: "Дата пролонгации",
  bonus_type: "Вид бонуса",
  full_name: "Полное название",
  head_counteragent_id: "Головной контрагент",
  head_counteragent_master_id: "Мастер ID головной контрагент",
  inn: "ИНН",
  contact_person: "Основное контактное лицо",
  activity_id: "Основной вид деятельности",
  contract_id: "Основной договор контрагента",
  contract_master_id: "Мастер ID основной договор контрагента",
  buyer_manager: "Основной менеджер покупателя",
  is_buyer: "Покупатель",
  is_supplier: "Поставщик",
  site_code: "ID контрагента на сайте",
  is_exclude_from_bonus_program: "Исключен из бонусной программы",
  business_region_id: "Бизнес регион",
  business_region_master_id: "Мастер ID бизнес регион ",
  parent_id: "Родительский регион",
  parent_master_id: "Мастер ID родительского региона",
  system_id: "Система",
  is_strength: "Усиленная",
  rear_is_strength: "Усиленная задняя",
  manufacturer_service_pim_manufacturers: "Производитель",
  supplierCondition: "Условия от производителя",
  product_types_service_pim_product_types: "Номенклатурная группа",
  sales_regions_service_dictionaries_geo_regions: "Регион продаж",
  notes: "Заметки",
  diameter: "Диаметр",
  number_days_calculate_value_money: "Кол-во дней для расчёта стоимости денег",
  value_calculate_value_money: "Значение для расчёта стоимости денег, %",
  purchase_input_value_calculation: "Значение для расчета входа закупа",
  rate_day_value_money: "Ставка день стоимости денег, %",
  value_money: "Стоимость денег, %",
  value_money_rub: "Стоимость денег, руб",
  payment_supplier: "Оплата поставщику",
  average_markup_ca_and_rc_balances: "Наценка средняя по остаткам ЦС и РЦ, %",
  markup_program: "Наценка по программе, %",
  commercial_bonus: "Коммерческий бонус, %",
  commercial_bonus_additional: "Дополнительный коммерческий бонус, %",
  marketing_bonus: "Маркетинговый бонус, %",
  tire_service_bonus: "Бонус за ШМ, %",
  prepaid_discount: "Скидка за предоплату, %",
  bonus_prepayment: "Бонус за предоплату, %",
  annual_bonus: "Годовой бонус, %",
  total_bonus: "Итого бонус, %",
  name_clean: "Нормализованное имя Бренда",
  synonyms: "Синонимы",
  status_task: "Статус задания",
  my_tasks: "Мои задания",
  subject: "Тема сообщения",
  alias: "Алиас",
  body_type: "Тип тела сообщения",
  provider_account_id: "Аккаунт провайдера",
  b2b_program: "Программа B2B",
  seasonal_program: "Сезонная программа",
  draft: "Черновик",
  work: "В работе",
  approving: "На согласовании",
  agreed: "Согласован",
  revoke: "Отозван",
  finish: "Завершен",
  is_done: "Отправлено",
  template_id: "Шаблон",
  from: "От",
  to: "Кому",
  sender_info: "Отправитель",
  status_text: "Текст статуса",
  is_staff: "Тип пользователя",
  recipient_brinex_id: "Получатель brinex_id",
  "created_at-ge": "Дата создания c",
  "created_at-le": "Дата создания по",
  program: "Программа",
  parent_company_service_dictionaries_counteragents:
    "Головная компания (холдинг)",
  organization_dku_service_dictionaries_counteragents: "Организация",
  recipient_of_bonuses_service_dictionaries_counteragents: "Получатель бонусов",
  agreement_service_dictionaries_contracts: "Договор",
  scope_of_legal_entity_service_dictionaries_regions: "Область действия юрлица",
  start_bonus: "Бонус период ОТ",
  end_bonus: "Бонус период ДО",
  "start_bonus:>=": "Бонус период начало ОТ",
  "start_bonus:<=": "Бонус период начало ДО",
  "end_bonus:>=": "Бонус период окончание ОТ",
  "end_bonus:<=": "Бонус период окончание ДО",
  "created_at:>=": "Бонус период ДКУ ОТ",
  "created_at:<=": "Бонус период ДКУ ДО",
  file_link: "Ссылка на файл с данными",
  item_count: "Количество элементов ",
  start_at: "Дата начала",
  end_at: "Дата окончания",
  "start_at:>=": "Дата начала ОТ",
  "start_at:<=": "Дата начала ДО",
  "end_at:>=": "Дата окончания ОТ",
  "end_at:<=": "Дата окончания ДО",
  total: "Всего элементов",
  is_leasing: "Лизинговый СЦ",
  code_site: "Код сайта",
  geo: "Координаты",
  location: "Расположение",
  fias: "ФИАС",
  recording_interval: "Интервал",
  has_terminals: "Есть терминалы",
  techrezerv_clear_start_hour:
    "Час, начиная с которого работает `расческа` по местному времени",
  comb_works_hours_ahead: "На сколько часов вперед работает `расческа`",
  processed_time: "Обработка данных",
  loading_time: "Получение данных из 1С",
  group_link: "Ссылка группы",
  group_name: "Название группы",
  service_center_id: "Сервисный центр",
  user_groups: "Группы",
  middle_name: "Отчество",
  auth_user: "Пользователь Brinex",
  brinex_id: "Пользователь Brinex",
  manufacturer_id: "Производитель",
  car_models: "Модели",
  radius: "Диаметр",
  vehicle_type: "Тип авто",
  is_complect: "Комплексная услуга",
  is_low_profile: "Низкий профиль",
  is_run_flat: "Run Flat",
  operation_id: "Услуга",
  serviceCenter: "Сервисный центр",
  period: "Дата",
  currency: "Валюта",
  "period:>=": "Дата ОТ",
  "period:<=": "Дата ДО",
  author_type: "Тип автора",
  external_system_id: "ID системы",
  storage_number: "Номер документа",
  season_storage_operation_id: "Услуга",
  appointment_id: "Заявка на ШМ",
  plate_number: "Гос. номер",
  barcode: "Штрихкод колеса",
  start_validity: "Начало срока действия",
  end_validity: "Конец срока действия",
  "start_validity:>=": "Начало срока действия ОТ",
  "start_validity:<=": "Начало срока действия ДО",
  "end_validity:>=": "Конец срока действия ОТ",
  "end_validity:<=": "Конец срока действия ДО",
  start_action: "Начало периода действия",
  end_action: "Конец периода действия",
  "start_action:>=": "Начало периода действия ОТ",
  "start_action:<=": "Начало периода действия ДО",
  "end_action:>=": "Конец периода действияОТ",
  "end_action:<=": "Конец периода действия ДО",
  active_dates: "Активность программы",
  "to:like": "Кому",
  log_status_error: "Имелись ошибки при отправке",
  months: "Количество месяцев",
  diameters: "Диаметры ИЛИ",
  all_diameters: "Диаметр И",
  is_assembled: "КВС",
  first_name: "Имя",
  is_verified: "Верифицирован",
  manufacturer_name: "Наименование производителя",
  manufacturer_code: "Код производителя",
  utc: "UTC",
  delay_days_from: "Кол-во дней отсрочки ОТ:",
  delay_days_to: "Кол-во дней отсрочки ДО:",
};

const cdpWords: Record<string, string> = {
  brand: "Бренд",
};

const nippleWords: Record<string, string> = {
  link: "Ссылка",
};

export const d = (
  name: string,
  defaultValue?: string,
  isCdp?: boolean,
  isNipple?: boolean
): string => {
  return (
    (isCdp ? cdpWords[name] : "") ||
    (isNipple ? nippleWords[name] : "") ||
    words[name] ||
    defaultValue ||
    name
  );
};

/**
 * Возвращает верное склонение слова
 * @param {number} value - Число элементов
 * @param {Array} words - Формы склонения слов в формате ["результат", "результата", "результатов"]
 * @return {string} Верное склонение слова
 * @example
 * // '20 результатов'
 * defaultTextInputRange(20, ["результат", "результата", "результатов"])
 */
export function stringDeclension(value: number, words: Array<string>): string {
  value = Math.abs(value) % 100;
  const num = value % 10;
  if (value > 10 && value < 20) {
    return words[2] || "";
  } else if (num > 1 && num < 5) {
    return words[1] || "";
  } else if (num == 1) {
    return words[0] || "";
  }
  return words[2] || "";
}
