import {
  BASE_URL,
  DEFAULT_HEADERS,
  defaultGetPaginationParams,
  getNotification,
} from "@/api/core/config";
import {
  FetchRequestArguments,
  GetErrorMessageArguments,
  GetParametersArgumentsDeclaration,
} from "@/api/core/types.d";
import {
  DefaultJsonFormContext,
  FormItemObjDeclaration,
} from "@/plugins/form-generator-json-v2/types";
import { user } from "@/model/user";
import { ElNotification } from "element-plus";
import { RequestsQueueController } from "@/api/core/requestsQueue";
import { d } from "@/helpers/dictionary";

const getErrorMessage = (payload: GetErrorMessageArguments): string => {
  return `fetch failed(${payload.point}) - status: ${payload.error.status}; detail: ${payload.error.detail}; title: ${payload.error.title}`;
};

export const fetchRequest = async ({
  point,
  payload,
  fetchMethod,
  isAuth,
  showNotification = true,
  canAborted = true,
  rawPromise = false,
  tokenAdmin,
  isNoMessage,
}: FetchRequestArguments): Promise<any> => {
  if (!isAuth) {
    await user.checkUserToken();
  }
  const url = `${BASE_URL}${point}`;
  const requestsQueueController = new RequestsQueueController(url);
  if (canAborted) {
    requestsQueueController.addRequestToQueue();
  }
  try {
    const response = await fetch(url, {
      method: fetchMethod,
      headers: {
        "X-Authorization": `Bearer ${user.getToken()}`,
        ...DEFAULT_HEADERS,
      },
      signal: requestsQueueController.controller.signal,
      ...(["POST", "PUT", "DELETE"].includes(fetchMethod) && {
        body: JSON.stringify(payload),
      }),
    });
    let responseJSON: Record<string, any> = {};
    try {
      if (!rawPromise) {
        responseJSON = await response.json();
        window.dispatchEvent(
          new CustomEvent("set-debug", {
            detail: {
              userOptions: {
                data: responseJSON,
                url,
                status: response.status,
              },
            },
          })
        );
      }
    } catch (error) {
      if (response.status !== 200) {
        throw new Error("Не удалось обработать результат запроса");
      }
      console.error(error);
    }

    if (response.status === 401 && !isAuth) {
      const res = await user.refresh();
      if (res) {
        return await fetchRequest({
          point,
          payload,
          fetchMethod,
          isAuth,
          showNotification,
          canAborted,
          rawPromise,
          tokenAdmin,
        });
      } else {
        user.goLogin();
        return responseJSON;
      }
    }

    if (response.status === 401 && isAuth) {
      return responseJSON;
    }

    if (response.status === 422) {
      const error = new Error(
        getErrorMessage({
          error: responseJSON.errors,
          point: point,
        })
      );
      const notification = getNotification(response.status, fetchMethod);
      const additionalError = responseJSON.source
        ? objectToString(responseJSON.source)
        : "";

      if (showNotification && (notification.message || additionalError)) {
        ElNotification({
          message: `${notification.message} ${additionalError}`,
          type: "error",
          duration: notification.duration || 5000,
        });
      }

      console.error(error);
      return responseJSON;
    }

    if (response.ok) {
      if (responseJSON.errors && Object.keys(responseJSON.errors).length) {
        const fullMessage = Object.keys(responseJSON.errors).reduce(
          (accum: string, id: number | string) =>
            `${accum} ${id}: ${responseJSON.errors[id].title}`,
          ""
        );

        ElNotification({
          message: fullMessage,
          type: "error",
          duration: 5000,
        });

        return rawPromise ? response : responseJSON;
      }

      if (!isNoMessage) {
        const notification = getNotification(200, fetchMethod);
        const { duration } = notification;
        const message = point.includes("pim/api/v1/task/cancel")
          ? "Задача отменена"
          : notification.message;

        if (showNotification && message) {
          ElNotification({
            message,
            type: "success",
            duration,
          });
        }
      }

      return rawPromise ? response : responseJSON;
    }

    const status = response.status >= 500 ? 500 : response.status;
    const { message } = getNotification(status, fetchMethod);
    const error: Partial<Error & { status: number }> = new Error(
      responseJSON.message || responseJSON.errors?.title || message
    );
    error.status = response.status;

    throw error;
  } catch (error: any) {
    const { name, status = 400 } = error;
    const message =
      error.message || getNotification(status, fetchMethod).message;

    if (showNotification && message && name !== "AbortError") {
      ElNotification({
        message,
        type: "error",
        duration: status === 500 ? 5000 : 3000,
      });
    }
    return { errors: error };
  } finally {
    requestsQueueController.removeRequestFromQueue();
  }
};

export const getPaginationQueryString = (
  parameters: GetParametersArgumentsDeclaration = {}
): string => {
  const queryParams: any = {
    ...defaultGetPaginationParams,
    ...parameters,
  };

  const url = new URLSearchParams(queryParams);

  for (const key in queryParams) {
    if (Array.isArray(queryParams[key])) {
      url.delete(key);
      queryParams[key].forEach((item: string) => {
        url.append(`${key}${!key.includes("[]") ? "[]" : ""}`, item);
      });
    }

    const isEmptyString =
      !queryParams[key] && typeof queryParams[key] === "string";

    if (isEmptyString) {
      url.delete(key);
    }
  }

  return url.toString();
};

const getItemProp = (obj: Record<string, any>, prop: string) => {
  const parts = prop.split(".");
  let value = obj;

  for (const part of parts) {
    if (!value || !Object.hasOwn(value, part)) {
      return {
        value: null,
        object: null,
      };
    }
    value = value[part];
  }

  return {
    prop: parts[0],
    value,
    object: typeof value === "object" ? value : obj,
  };
};

export const payloadConstructor = (
  formCtx: DefaultJsonFormContext,
  beingSentProps?: string[]
): any => {
  const hasMapCbItemList = formCtx.declaration.items.filter(
    (item) => item.submitMapDataCallback
  );
  const mapRules = hasMapCbItemList.reduce((target: any, key) => {
    target[key.model] = key.submitMapDataCallback;
    return target;
  }, {});

  const result = {} as Record<string, any>;

  if (typeof beingSentProps === "undefined") {
    const groupListItems: Array<string> = [];

    formCtx.declaration.items
      .filter((item) => item.groupList?.length)
      .forEach((item) => {
        const itemValue = formCtx.formModel[item.model];
        if (
          item.isPayload &&
          (!itemValue ||
            (typeof itemValue === "object" && !Object.values(itemValue).length))
        ) {
          return;
        }

        item.groupList?.forEach((groupListItem) => {
          if (item.isPayload && groupListItem.isPayload) {
            groupListItems.push(`${item.name}.${groupListItem.name}`);
          } else if (
            !item.isPayload &&
            groupListItem.isPayload &&
            !groupListItem.attrs?.disabled
          ) {
            groupListItems.push(groupListItem.name);
          }
        });
      });
    beingSentProps = formCtx.declaration.items
      .filter((item) => item.isPayload && !item.groupList?.length)
      .map((item) => item.name);

    beingSentProps = [...beingSentProps, ...groupListItems];
  }

  if (beingSentProps.length) {
    beingSentProps.forEach((item: string) => {
      let elementItem: FormItemObjDeclaration | undefined;

      formCtx.declaration.items.forEach((element) => {
        if (element.groupList?.length) {
          for (const groupListElement of element.groupList) {
            if (item === `${element.name}${groupListElement.name}`) {
              elementItem = groupListElement;
            }
          }
        } else if (element.name === item) {
          elementItem = element;
        }
      });

      const elementType = elementItem?.element || "";
      const elementCanSkipEmpty = !!elementItem?.doNotPassEmpty;
      const checkEmptyValue = [
        "switch",
        "switch-admin",
        "checkbox",
        "radio",
      ].includes(elementType);
      const modelsWithNumberNull: Array<string | number> = [
        "filter[binding]",
        "filter[active]",
        "filter[is_active]",
        "filter[is_common]",
        "filter[trashed]",
        "filter[is_stock]",
      ];
      const itemForm = getItemProp(formCtx.formModel, item as string);

      if (
        (!itemForm.value &&
          itemForm.value !== 0 &&
          !modelsWithNumberNull.includes(item) &&
          !checkEmptyValue &&
          elementCanSkipEmpty) ||
        !(itemForm.prop || "" in formCtx.formModel)
      ) {
        return;
      }

      const value = mapRules[item]
        ? mapRules[item](formCtx.formModel[item])
        : itemForm.value;

      if (elementItem?.extractedFrom) {
        if (!result[elementItem.extractedFrom]) {
          result[elementItem.extractedFrom] = {};
        }

        result[elementItem.extractedFrom][item] = value;
        return;
      }

      let key = "";
      item.split(".").forEach((splittedItem, index) => {
        if (!key) {
          key = splittedItem;
        }

        if (!result[key]) {
          result[key] = item.split(".").length === 1 ? value : {};
        } else if (item.split(".").length > 1) {
          if (index === splittedItem.split(".").length) {
            result[key][splittedItem] = value;
          } else {
            result[key][splittedItem] = {};
            key = splittedItem;
          }
        }
      });
    });
  } else {
    for (const key in formCtx.formModel) {
      if (key in formCtx.formModel)
        result[key] = mapRules[key]
          ? mapRules[key](formCtx.formModel[key])
          : formCtx.formModel[key];
    }
  }

  for (const key in result) {
    if (
      result[key] === undefined ||
      result[key] === "" ||
      (key.includes("filter[") && result[key] === null)
    ) {
      delete result[key];
    }
  }

  return result;
};

export function errorHandler(
  response: Record<string, any>,
  callbackErrors: (errors: Array<any>) => void,
  defaultMessage = ""
): void {
  let { message = "", source } = response || {};

  if (source) {
    if (!Array.isArray(source) && (source.title || source.message)) {
      source = [[source.title || source.message]];
    }
    callbackErrors(source);
    return;
  }

  message = message || defaultMessage;

  if (message) {
    ElNotification({
      message,
      type: "error",
      duration: 5000,
    });
  }
}

const objectToString = (source: any) => {
  const strArr: Array<string> = [];
  Object.keys(source).forEach((item: string) => {
    strArr.push(`${d(item)}: ${source[item]}`);
  });
  return strArr.join("\n");
};
