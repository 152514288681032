import { RouteRecordRaw } from "vue-router";
import { DocumentChecked } from "@element-plus/icons-vue";
import { RouteAccess } from "../types";
import {
  CDP,
  DKU,
  DKU_CREATE,
  DKU_EDIT,
  PROGRAMS,
  PROGRAMS_CREATE,
  PROGRAMS_EDIT,
  SETTINGS,
  SUPPLIER_CONDITIONS,
} from "@/dictionary/header";
import WorkingSectionView from "@/views/WorkingSectionView.vue";
import LayoutWorkingSection from "@/layout/LayoutWorkingSection.vue";
import { ServiceName } from "@/constants";

const access: RouteAccess = {
  service: ServiceName.cdp,
  object: "",
  command: "index",
};

const CDP_PATH = `/${ServiceName.cdp}`;

export const cdpRoutesList: RouteRecordRaw[] = [
  {
    path: CDP_PATH,
    name: ServiceName.cdp,
    component: LayoutWorkingSection,
    meta: {
      title: CDP,
      icon: DocumentChecked,
      clickable: false,
      isDev: true,
    },
    children: [
      {
        path: `${CDP_PATH}/supplier-conditions`,
        name: "supplier-conditions",
        component: WorkingSectionView,
        meta: {
          title: SUPPLIER_CONDITIONS,
          access: {
            ...access,
            object: "supplier_conditions",
          },
        },
      },
      {
        path: `${CDP_PATH}/programs`,
        name: "programs",
        component: WorkingSectionView,
        meta: {
          title: PROGRAMS,
          shouldNavigateToDetailPage: true,
          access: {
            ...access,
            object: "programs",
          },
        },
      },
      {
        path: `${CDP_PATH}/programs/create/:id?`,
        name: "programs-create",
        component: (): Promise<typeof import("*.vue")> =>
          import(/* webpackChunkName: "Versions" */ "@/views/cdp/Programs.vue"),
        meta: {
          title: PROGRAMS_CREATE,
          access: {
            ...access,
            object: "programs",
            command: "store",
          },
          hiddenRoute: true,
        },
      },
      {
        path: `${CDP_PATH}/programs/edit/:id?`,
        name: "programs-edit",
        component: (): Promise<typeof import("*.vue")> =>
          import(/* webpackChunkName: "Versions" */ "@/views/cdp/Programs.vue"),
        meta: {
          title: PROGRAMS_EDIT,
          access: {
            ...access,
            object: "programs",
            command: "update",
          },
          hiddenRoute: true,
        },
      },
      {
        path: `${CDP_PATH}/settings`,
        name: "settings",
        component: (): Promise<typeof import("*.vue")> =>
          import(/* webpackChunkName: "Versions" */ "@/views/cdp/Settings.vue"),
        meta: {
          title: SETTINGS,
          access: {
            ...access,
            object: "settings",
          },
        },
      },
      {
        path: `${CDP_PATH}/dku`,
        name: "dku",
        component: WorkingSectionView,
        meta: {
          title: DKU,
          shouldNavigateToDetailPage: true,
          access: {
            ...access,
            object: "commercial_terms_documents",
          },
        },
      },
      {
        path: `${CDP_PATH}/dku/create/:id?`,
        name: "dku-create",
        component: (): Promise<typeof import("*.vue")> =>
          import(/* webpackChunkName: "Versions" */ "@/views/cdp/Dku.vue"),
        meta: {
          title: DKU_CREATE,
          access: {
            ...access,
            object: "commercial_terms_documents",
            command: "store",
          },
          hiddenRoute: true,
        },
      },
      {
        path: `${CDP_PATH}/dku/edit/:id?`,
        name: "dku-edit",
        component: (): Promise<typeof import("*.vue")> =>
          import(/* webpackChunkName: "Versions" */ "@/views/cdp/Dku.vue"),
        meta: {
          title: DKU_EDIT,
          access: {
            ...access,
            object: "commercial_terms_documents",
            command: "update",
          },
          hiddenRoute: true,
        },
      },
    ],
  },
];
