import { readonly, ref, Ref, watch } from "vue";
import {
  RouteLocationNormalizedLoaded,
  RouteParamsRaw,
  Router,
  useRouter,
} from "vue-router";
import { QUERY_PARAM } from "@/components/config";

let _router: Router;
let _queryParam: string;
let _otherParams: RouteParamsRaw;
const isOpen = ref(false);
const initPath = ref("");

const setOpen = (value: boolean): void => {
  isOpen.value = value;
};

const show = (nameModal: string): void => {
  const currentValue = _router.currentRoute.value.params[_queryParam];

  if (currentValue === nameModal) {
    setOpen(true);
    return;
  }

  const { name = "index", params } = _router.currentRoute.value;

  if (name) {
    _router.push({
      name,
      params: {
        ...params,
        ..._otherParams,
        [_queryParam]: nameModal,
      },
    });
  }
};

const hide = (): void => {
  if (initPath.value === _router.currentRoute.value.fullPath) {
    initPath.value = "";
    const actionModal = _router.currentRoute.value?.params?.actionModal;

    if (actionModal?.length) {
      _router.currentRoute.value.path = _router.currentRoute.value.path.split(
        `/${actionModal}`
      )[0] as string;
      _router.push("");
    } else {
      _router.push({ ..._router.currentRoute.value });
    }

    setOpen(false);
    return;
  }

  _router.back();
};

interface Return {
  isOpen: Readonly<Ref<boolean>>;
  show: (nameModal: string) => void;
  hide: () => void;
}

export default (
  queryParam: string = QUERY_PARAM,
  otherParams: RouteParamsRaw = {}
): Return => {
  _router = useRouter();
  _queryParam = queryParam;
  _otherParams = otherParams;
  initPath.value = _router.currentRoute.value.fullPath;

  watch(
    () => _router.currentRoute.value,
    (route: RouteLocationNormalizedLoaded) => {
      const hasQueryParam = _queryParam in route.params;
      const emptyQueryParam = route.params[_queryParam] === "";
      setOpen(hasQueryParam && !emptyQueryParam);
    }
  );

  return {
    isOpen: readonly(isOpen),
    show,
    hide,
  };
};
