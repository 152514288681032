<template>
  <section :class="$style['table-wrapper']">
    <section
      v-show="dataList.loading"
      v-loading.lock="dataList.loading"
      :class="$style['table-loader']"
    />
    <the-scroll-controls v-if="!isMobile" :dataList="dataListTable" />

    <el-table
      v-if="!dataList.loading"
      ref="tableInstance"
      :cell-class-name="cellClassName"
      :class="{ 'auto-width': isWizard, 'hide-burger': hideBurger }"
      :data="dataListTable"
      :default-sort="sortRule"
      :row-class-name="rowClassName || getTableRowClass"
      :show-header="showHeader"
      border
      table-layout="fixed"
      @sort-change="sortHandler"
      @selection-change="selectionChangeHandler"
      @select-all="handleSelectAll"
      @cell-click="clickHandler"
      @row-dblclick="doubleClickHandler"
      @cell-mouse-enter="mouseEnterHandler"
      @cell-mouse-leave="mouseLeaveHandler"
      @expand-change="onExpandChange"
      @header-dragend="headerDragendEvent"
    >
      <el-table-column
        :class-name="bulks ? 'bulk' : ''"
        :type="bulks ? 'selection' : ''"
        :width="bulks ? 56 : 6"
      />
      <el-table-column
        v-if="!hideBurger"
        :class-name="$style['col-burger']"
        :resizable="false"
        width="56"
      >
        <template #default="scope">
          <slot :column="scope.column" :row="scope.row" name="actions"></slot>
        </template>
      </el-table-column>
      <el-table-column v-if="hasExpandColumn()" type="expand" width="34">
        <template #default="scope">
          <el-table
            :data="expandedRows.filter((row: Record<string, any>) => row.id === scope.row.id)"
            :show-header="false"
            table-layout="auto"
          >
            <el-table-column class-name="empty-col" width="56" />
            <el-table-column class-name="empty-col" width="56" />
            <el-table-column class-name="empty-col" width="34" />
            <el-table-column
              v-for="(expandCol, index) in colsWithSettings"
              :key="`${expandCol.key}-expand-${index}`"
              :align="expandCol.align || 'left'"
              :class-name="`${expandCol.class} ${expandCol.key} ${
                expandCol.children?.length ? 'expand-col' : ''
              }`"
              :prop="expandCol.key"
              :sortable="false"
              :type="void 0"
              v-bind="expandCol"
            >
              <template #default="scope">
                <template
                  v-if="
                    scope.row.relationships.rear && expandCol.children?.length
                  "
                >
                  <el-table
                    :class="$style['expand-table']"
                    :data="[expandCol.children[scope.$index]]"
                    :show-header="false"
                    table-layout="auto"
                  >
                    <el-table-column
                      v-for="(childrenCol, index) in expandCol.children"
                      :key="`${childrenCol.key}-${index}`"
                      :align="childrenCol.align || 'left'"
                      :class-name="childrenCol.class"
                      :label="
                        (childrenCol.label !== undefined
                          ? childrenCol.label
                          : d(childrenCol.key || '')) || ''
                      "
                      :prop="`relationships.rear.attributes.${childrenCol.key}`"
                      :sort-orders="[SortOrderType.asc, SortOrderType.desc]"
                      :type="void 0"
                      v-bind="childrenCol"
                    >
                      <template #default>
                        <the-mobile-table-title
                          :col="childrenCol"
                          :parentCol="expandCol"
                        />
                        <template
                          v-if="childrenCol.type === ColumnType.boolean"
                        >
                          <template
                            v-if="
                              scope.row.relationships?.rear?.attributes[
                                childrenCol.key
                              ]
                            "
                          >
                            <el-icon class="success">
                              <circle-check-filled />
                            </el-icon>
                          </template>
                          <template
                            v-if="
                              !scope.row.relationships?.rear?.attributes[
                                childrenCol.key
                              ]
                            "
                          >
                            <el-icon class="fail">
                              <circle-close-filled />
                            </el-icon>
                          </template>
                        </template>
                        <template v-else-if="childrenCol.key === 'type'">
                          {{
                            wheelTypeText(
                              scope.row.relationships?.rear?.attributes
                            )
                          }}
                        </template>
                        <template
                          v-else-if="childrenCol.key === 'tire_sizing_system'"
                        >
                          {{
                            tireSizingSystemText(
                              scope.row.relationships?.rear?.attributes[
                                childrenCol.key
                              ]
                            )
                          }}
                        </template>
                        <template v-else-if="childrenCol.selectOptions">
                          {{
                            selectOption(
                              childrenCol,
                              scope.row.relationships?.rear?.attributes
                            )
                          }}
                        </template>
                        <template v-else>
                          {{
                            scope.row.relationships?.rear?.attributes[
                              childrenCol.key
                            ] || "-"
                          }}
                        </template>
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
                <template
                  v-else-if="
                    scope.row.relationships.rear && isActive(expandCol.key)
                  "
                >
                  <el-tooltip
                    :content="
                      scope.row.relationships.rear.attributes[expandCol.key]
                        ? 'Активный'
                        : 'Неактивный'
                    "
                    class="box-item"
                    effect="light"
                    placement="top"
                    popper-class="tooltip-default"
                  >
                    <div
                      :class="[
                        'dot',
                        {
                          success:
                            scope.row.relationships.rear.attributes[
                              expandCol.key
                            ],
                          inactive:
                            !scope.row.relationships.rear.attributes[
                              expandCol.key
                            ],
                        },
                      ]"
                    />
                  </el-tooltip>
                </template>
                <template
                  v-else-if="
                    expandCol.isRelationship &&
                    Array.isArray(scope.row?.relationships?.[expandCol.key])
                  "
                >
                  <the-mobile-table-title :col="expandCol" />
                  <div
                    v-for="item in scope.row?.relationships?.[expandCol.key]"
                    :key="item?.id"
                  >
                    <el-icon>
                      <Phone v-if="item?.attributes?.type === 'phone'" />
                      <Message v-if="item?.attributes?.type === 'email'" />
                    </el-icon>
                    <template v-if="expandCol.displayFields">
                      <template
                        v-for="(field, key) in expandCol.displayFields"
                        :key="key"
                      >
                        {{ item.type === key ? item?.attributes[field] : "-" }}
                      </template>
                    </template>
                    <template v-else>
                      {{
                        item?.attributes?.name || item?.attributes?.data || "-"
                      }}
                    </template>
                  </div>
                </template>
                <template
                  v-else-if="
                    expandCol.isRelationship &&
                    !Array.isArray(scope.row?.relationships?.[expandCol.key])
                  "
                >
                  <the-mobile-table-title :col="expandCol" />
                  {{
                    getRelationship(expandCol.key, scope.row)?.attributes
                      ?.name ||
                    getRelationship(expandCol.key, scope.row)?.attributes
                      ?.data ||
                    "-"
                  }}
                </template>

                <template v-else-if="scope.row.relationships.rear">
                  <the-mobile-table-title :col="expandCol" />

                  {{
                    getAssociatedLabelByOption(
                      scope.row.relationships.rear[expandCol.key] ||
                        scope.row.relationships.rear.attributes[expandCol.key],
                      expandCol.selectOptions
                    )
                  }}
                </template>

                <template v-else></template>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(col, index) in colsWithSettings"
        :key="`${col.key}-${index}`"
        :align="col.align || 'left'"
        :class-name="`${col.class} ${col.resize ? 'active-resize' : ''}`"
        :label="col.label !== undefined ? col.label : d(col.key)"
        :prop="col.key"
        :resizable="col.resize"
        :sort-orders="[SortOrderType.asc, SortOrderType.desc]"
        :type="void 0"
        v-bind="col"
      >
        <template #header>
          <el-popover
            :content="col.label !== undefined ? col.label : d(col.key)"
            :offset="2"
            :popper-class="$style['custom-style-el-popover']"
            :trigger="popoverTypeTrigger"
            placement="top"
          >
            <template #reference>
              <span>{{
                col.label !== undefined ? col.label : d(col.key)
              }}</span>
            </template>
          </el-popover>
        </template>
        <template #default="scope">
          <template v-if="col.children">
            <el-table-column
              v-for="(childrenCol, index) in col.children"
              :key="`${childrenCol.key}-${index}`"
              :align="childrenCol.align || 'left'"
              :class-name="`${childrenCol.class} ${
                childrenCol.resize ? 'active-resize' : ''
              }`"
              :label="
                childrenCol.label !== undefined
                  ? childrenCol.label
                  : d(childrenCol.key)
              "
              :prop="childrenCol.key"
              :sort-orders="[SortOrderType.asc, SortOrderType.desc]"
              :type="void 0"
              v-bind="childrenCol"
            >
              <template #header>
                <el-popover
                  :content="
                    childrenCol.label !== undefined
                      ? childrenCol.label
                      : d(childrenCol.key)
                  "
                  :offset="2"
                  :popper-class="$style['custom-style-el-popover']"
                  :trigger="popoverTypeTrigger"
                  placement="top"
                >
                  <template #reference>
                    <span>{{
                      childrenCol.label !== undefined
                        ? childrenCol.label
                        : d(childrenCol.key)
                    }}</span>
                  </template>
                </el-popover>
              </template>
              <template #default="innerScope">
                <the-mobile-table-title :col="childrenCol" :parentCol="col" />
                <template v-if="childrenCol.type === ColumnType.boolean">
                  <template v-if="innerScope.row[childrenCol.key]">
                    <el-icon class="success">
                      <circle-check-filled />
                    </el-icon>
                  </template>
                  <template v-if="!innerScope.row[childrenCol.key]">
                    <el-icon class="fail">
                      <circle-close-filled />
                    </el-icon>
                  </template>
                </template>
                <template
                  v-else-if="hasExpandColumn && childrenCol.key === 'type'"
                >
                  {{ wheelTypeText(innerScope.row) }}
                </template>
                <template v-else-if="childrenCol.key === 'tire_sizing_system'">
                  {{ tireSizingSystemText(innerScope.row[childrenCol.key]) }}
                </template>
                <template v-else-if="childrenCol.selectOptions">
                  {{ selectOption(childrenCol, innerScope.row) }}
                </template>
                <template v-else>
                  {{ innerScope.row[childrenCol.key] || "-" }}
                </template>
              </template>
            </el-table-column>
          </template>
          <template v-else-if="isActive(col.key)">
            <div
              v-tooltip="{
                text: scope.row[col.key] ? 'Активный' : 'Неактивный',
              }"
              :class="[
                { success: scope.row[col.key], inactive: !scope.row[col.key] },
                'dot',
              ]"
            />
          </template>
          <template
            v-else-if="col.type === ColumnType.boolean && col.key !== 'binding'"
          >
            <the-mobile-table-title :col="col" />
            <template v-if="scope.row[col.key]">
              <el-icon class="success">
                <circle-check-filled />
              </el-icon>
            </template>
            <template v-if="!scope.row[col.key]">
              <el-icon class="fail">
                <circle-close-filled />
              </el-icon>
            </template>
          </template>
          <template v-else-if="col.key === 'binding'">
            <the-mobile-table-title :col="col" />
            <el-icon v-if="!scope.row[col.key]" class="success">
              <circle-check />
            </el-icon>
            <el-icon v-else class="fail">
              <icon-chain />
            </el-icon>
          </template>
          <template v-else-if="col.key === 'attributes'">
            <the-mobile-table-title :col="col" />
            <el-icon v-if="scope.row.attributes_status" class="success">
              <circle-check />
            </el-icon>
            <div v-else class="fail">
              <el-icon>
                <icon-chain />
              </el-icon>
              <span v-if="scope.row.attributes_text" class="spaced">{{
                scope.row.attributes_text
              }}</span>
            </div>
          </template>
          <template v-else-if="col.type === ColumnType.activity">
            <template v-if="scope.row[col.key]">
              <el-tag> Активный</el-tag>
            </template>
            <template v-if="!scope.row[col.key]">
              <el-tag type="danger"> Неактивный</el-tag>
            </template>
          </template>
          <template v-else-if="col.type === ColumnType.picture">
            <the-mobile-table-title :col="col" />
            <template
              v-if="
                col.isRelationship &&
                Array.isArray(scope.row?.relationships?.images) &&
                scope.row?.relationships?.images.length
              "
            >
              <img
                :src="`${PUBLIC_SERVICE_POINT_NAME}/pim/${col.objectType}/stream/${scope.row.relationships.images[0].id}?width=100`"
                alt="picture"
                height="50"
                width="50"
              />
            </template>
            <template
              v-else-if="
                col.isRelationship &&
                Array.isArray(scope.row?.relationships?.logo) &&
                scope.row?.relationships?.logo.length
              "
            >
              <img
                :src="`${PUBLIC_SERVICE_POINT_NAME}/vehicles/${scope.row?.relationships?.logo[0].attributes.object_type}/stream/${scope.row.relationships.logo[0].id}?width=50`"
                alt="picture"
                height="50"
                width="50"
              />
            </template>
            <template v-else>
              <span> - </span>
            </template>
          </template>
          <template v-else-if="col.type === ColumnType.dateWithoutTimezone">
            <the-mobile-table-title :col="col" />
            {{
              scope.row[col.key]
                ? formatDate(scope.row[col.key], true, true)
                : "-"
            }}
          </template>
          <template v-else-if="col.type === ColumnType.shortDate">
            <the-mobile-table-title :col="col" />
            {{ scope.row[col.key] ? toWdYMDDate(scope.row[col.key]) : "-" }}
          </template>
          <template v-else-if="tableDateTypesFormatTo.includes(col.type)">
            <the-mobile-table-title :col="col" />
            {{
              scope.row[col.key]
                ? formatDate(
                    scope.row[col.key],
                    col.type === ColumnType.dateWithSeconds
                  )
                : "-"
            }}
          </template>
          <template v-else-if="col.dataKey">
            <template v-if="Array.isArray(scope.row[col.key])">
              <span v-for="item in scope.row[col.key]" :key="item?.id">
                {{ item[col.dataKey] }}
              </span>
            </template>
            <template v-else-if="scope.row[col.key]?.[col.dataKey]">
              {{ scope.row[col.key][col.dataKey] }}
            </template>
          </template>
          <template
            v-else-if="
              col.relData &&
              scope.row?.relationships?.[col.relData]?.attributes?.name
            "
          >
            {{
              scope.row.relationships[col.relData].attributes[col.relDataKey] ||
              scope.row.relationships[col.relData].attributes.name
            }}
          </template>
          <template
            v-else-if="
              col.attrKey && scope.row?.[col.key]?.attributes?.[col.attrKey]
            "
          >
            {{ scope.row[col.key].attributes[col.attrKey] }}
          </template>
          <template
            v-else-if="
              col.isRelationship &&
              Array.isArray(scope.row?.relationships?.[col.key])
            "
          >
            <the-mobile-table-title :col="col" />
            <div
              v-for="item in scope.row?.relationships?.[col.key]"
              :key="item?.id"
            >
              <el-icon v-if="item?.attributes?.type">
                <Phone v-if="item?.attributes?.type === 'phone'" />
                <Message v-if="item?.attributes?.type === 'email'" />
              </el-icon>
              <template v-if="col.displayFields">
                <template v-for="(field, key) in col.displayFields" :key="key">
                  {{ item.type === key ? item?.attributes[field] : "-" }}
                </template>
              </template>
              <template v-else>
                {{
                  truncateText(item?.attributes?.name) ||
                  truncateText(item?.attributes?.title) ||
                  truncateText(item?.attributes?.data) ||
                  "-"
                }}
              </template>
            </div>
          </template>
          <template
            v-else-if="
              col.isRelationship &&
              !Array.isArray(scope.row?.relationships?.[col.key])
            "
          >
            <the-mobile-table-title :col="col" />
            {{
              truncateText(
                getRelationship(col.key, scope.row)?.attributes?.name
              ) ||
              truncateText(
                getRelationship(col.key, scope.row)?.attributes?.data
              ) ||
              "-"
            }}
          </template>
          <template v-else-if="typeof col.type === ColumnType.function">
            <the-mobile-table-title :col="col" />
            <router-link
              v-if="col.class === 'amount'"
              :to="`${col.type(scope.row)?.link}${
                filterAttributes
                  ? '?filter[attributes]=' + filterAttributes
                  : ''
              }`"
            >
              <div></div>
              <div>{{ col.type(scope.row)?.value }}</div>
              <div>
                <el-icon :size="16">
                  <arrow-right />
                </el-icon>
              </div>
            </router-link>
            <component
              :is="col.type(scope.row, col.key)"
              v-else-if="col.class == 'tsx'"
            />
            <template v-else>
              <span v-html="col.type(scope.row, col.key)" />
              <div v-if="isWheels && scope.row.relationships?.rear">
                {{ col.type(scope.row.relationships.rear.attributes, col.key) }}
              </div>
            </template>
          </template>
          <template v-else-if="col.selectOptions">
            <the-mobile-table-title :col="col" />
            {{ selectOption(col, scope.row) }}
          </template>
          <template v-else-if="col.key === 'openModal'">
            <slot :column="scope.column" :row="scope.row" name="openModal" />
          </template>
          <template v-else-if="col.key === 'openItem'">
            <slot :column="scope.column" :row="scope.row" name="openItem" />
          </template>
          <template v-else-if="col.showName && scope.row[col.key]?.name">
            {{ scope.row[col.key].name }}
          </template>
          <template v-else>
            <the-mobile-table-title :col="col" />
            <template v-if="scope.row[col.key] === 0">0</template>
            <template v-else-if="col.dictionary">
              {{ d(scope.row[col.key]) }}
            </template>
            <template v-else>
              {{ truncateText(scope.row[col.key]) || "-" }}
            </template>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        v-if="strutWidth !== 0"
        id="colStrutWidth"
        :resizable="false"
        :width="strutWidth"
      />
      <el-table-column
        v-if="!hideActions"
        :resizable="false"
        align="left"
        class-name="el-table__cell--actions"
        fixed="right"
        width="120"
      >
        <template #header>
          <el-popover
            :offset="2"
            :popper-class="$style['custom-style-el-popover']"
            content="Действия"
            placement="top"
            trigger="hover"
          >
            <template #reference>
              <span>Действия</span>
            </template>
          </el-popover>
        </template>
        <template #default="scope">
          <the-mobile-table-title text="Действия" />
          <slot
            :column="scope.column"
            :row="scope.row"
            name="wheelsActions"
          ></slot>
        </template>
      </el-table-column>
    </el-table>
  </section>
</template>

<script lang="ts" setup>
import { PUBLIC_SERVICE_POINT_NAME } from "@/api/points/files-api/service-config";
import { computed, onMounted, ref, toRaw, watch } from "vue";
import {
  ArrowRight,
  CircleCheck,
  CircleCheckFilled,
  CircleCloseFilled,
  Message,
  Phone,
} from "@element-plus/icons-vue";
import iconChain from "@/static/icons/chain.svg";
import useSort, {
  InitialSortParams,
  SortOrderType,
} from "@/composables/hooks/useSort";
import {
  CellCls,
  Sort,
} from "element-plus/es/components/table/src/table/defaults";
import { DataList } from "@/components/types";
import formatDate, { toWdYMDDate } from "@/helpers/formatDate";
import { d } from "@/helpers/dictionary";
import getRelationship from "@/helpers/getRelationship";
import { isActive } from "@/helpers/сomparisonStaticValues";
import useSelection from "@/composables/hooks/useSelection";
import TheScrollControls from "@/components/the/TheScrollControls.vue";
import TheMobileTableTitle from "@/components/the/TheMobileTableTitle.vue";
import vTooltip from "@/directives/vTooltip";
import { TableColumn } from "@/model/working-sections/types";
import { useRoute, useRouter } from "vue-router";
import useAdaptive from "@/composables/hooks/useAdaptive";
import { user } from "@/model/user";
import isColStrutWidth from "@/helpers/setStretchColumn";
import wheelTypeText from "@/helpers/wheelTypeText";
import { truncateText } from "@/helpers/string-utils";
import { TooltipTriggerType } from "element-plus";
import { useTableCellFormatting } from "@/components/working-sections/default/composables/useTableCellFormatting";
import {
  ColumnType,
  tableDateTypesFormatTo,
} from "@/model/working-sections/constants";

const { isMobile } = useAdaptive();

interface Props {
  isWizard: boolean;
  dataList: DataList;
  cols: Record<string, TableColumn>;
  bulks?: boolean;
  hideBurger?: boolean;
  hideActions?: boolean;
  selectionChangeHandler?: (...payload: any[]) => void;
  clickHandler?: (...payload: any[]) => void;
  doubleClickHandler?: (...payload: any[]) => void;
  mouseEnterHandler?: (...payload: any[]) => void;
  mouseLeaveHandler?: (...payload: any[]) => void;
  rowClassName?: ({ row }: { row: any }) => string;
  cellClassName?: CellCls<any>;
  showHeader?: boolean;
  filterAttributes?: any;
  isWheels?: boolean;
  initSort?: InitialSortParams;
}

const props = withDefaults(defineProps<Props>(), {
  hideBurger: false,
  hideActions: true,
  showHeader: true,
  selectionChangeHandler: () => {
    return;
  },
  clickHandler: () => {
    return;
  },
  doubleClickHandler: () => {
    return;
  },
});

const emit = defineEmits(["expand-change"]);

const { sortProp, sortOrder, setSortProp, setSortOrder } = useSort(
  props?.initSort
);
const { selection, maxSelection } = useSelection();
const { getAssociatedLabelByOption } = useTableCellFormatting();
const router = useRouter();
const route = useRoute();

const userOptions = ref([]);
const path = computed((): string => route.path.replaceAll(/\/[0-9]+/g, ""));
const strutWidth = ref(0);

const isWizardLocal = router.currentRoute.value.path.includes("wizard");

const popoverTypeTrigger = ref<TooltipTriggerType>("click");

const tableInstance = ref();
const sortRule = computed<Sort>(() => ({
  prop: sortProp.value,
  order: sortOrder.value,
}));
const expandedRows = ref<Record<string, any>[]>([]);

const colsWithSettings = computed((): TableColumn[] => {
  let index = 0;
  let cols: TableColumn[] = [];
  const groupsPositions: Record<string, number> = {};
  const colsWidth = userOptions.value[path.value]?.colsWidth || [];

  for (const col in props.cols) {
    const currentColumn = {
      ...props.cols[col],
      key: col,
    };

    const position = currentColumn?.settings?.sort ?? index;
    currentColumn.index = position;

    if (
      currentColumn?.settings?.visible === false ||
      currentColumn?.onlyEdit ||
      (!isMobile.value && currentColumn.class === "mobile") ||
      (isMobile.value && currentColumn.group && currentColumn.key !== "type")
    ) {
      continue;
    }

    if (currentColumn.group) {
      const groupPosition = Object.hasOwn(groupsPositions, currentColumn.group);
      if (!groupPosition) {
        groupsPositions[currentColumn.group] = index;

        const colsWithKeys = Object.keys(props.cols).map((key) => {
          return {
            key,
            ...props.cols[key],
          };
        });

        const width = colsWithKeys.reduce(
          (acc: number, current: TableColumn) => {
            if (
              current.group === currentColumn.group &&
              current.settings?.visible !== false
            ) {
              acc +=
                colsWidth.find((el) => el.name === current.key)?.width ||
                current.width ||
                150;
            }
            return acc;
          },
          0
        );

        cols[index] = {
          key: currentColumn.group,
          class: "is-group",
          width,
          children: [currentColumn],
          index: position,
        };
      } else {
        currentColumn.width =
          colsWidth.find((el: TableColumn) => el.name === currentColumn.key)
            ?.width ??
          props.cols[currentColumn.key].width ??
          150;

        const childrenPosition = groupsPositions[currentColumn.group];
        if (childrenPosition !== undefined) {
          cols[childrenPosition]?.children?.push({
            ...currentColumn,
            group: currentColumn.group,
          });
        }
      }
    } else {
      if (isWizardLocal) {
        currentColumn.resize = false;
      }

      currentColumn.width =
        colsWidth.find((el) => el.name === currentColumn.key)?.width ||
        currentColumn.width;

      cols[index] = currentColumn;
    }
    index++;
  }

  cols = cols.sort((col1, col2) =>
    (col1 as any)?.index > (col2 as any)?.index ? 1 : -1
  );

  return cols.reduce((acc: any[], current) => {
    if (!current) {
      return acc;
    }

    if (current.children) {
      current.children.sort((col1, col2) =>
        (col1 as any)?.index > (col2 as any)?.index ? 1 : -1
      );
    }

    return [...acc, current];
  }, []);
});

const onExpandChange = (
  _row: Record<string, any>,
  rows: Record<string, any>[]
) => {
  expandedRows.value = rows;
  emit("expand-change", expandedRows.value);
};

const getDataColsWidthUserOption = () => {
  userOptions.value = JSON.parse(localStorage.getItem("user_options") || "{}");
};
getDataColsWidthUserOption();

const changeColsWidthUserOption = async (colsWidth, colStrutWidth) => {
  await user.updateUserOptions(
    {
      [path.value]: {
        ...userOptions.value[path.value],
        colsWidth,
        colStrutWidth,
      },
    },
    true
  );
};

const headerDragendEvent = (newWidth, oldWidth, column) => {
  let width = 0;

  const colStrutWidth = {
    width: strutWidth.value,
  };

  if (isColStrutWidth.value) {
    oldWidth > newWidth &&
      (width = colStrutWidth.width + (oldWidth - newWidth));
    oldWidth < newWidth &&
      (width = colStrutWidth.width - (newWidth - oldWidth));

    strutWidth.value = width > 0 ? width : 0;
    colStrutWidth.width = width > 0 ? width : 0;
  } else {
    strutWidth.value = width;
    colStrutWidth.width = width;
  }

  getDataColsWidthUserOption();

  const colsWidth = userOptions.value[path.value]?.colsWidth || [];

  const pushedColsWidth = () => {
    colsWidth.push({
      name: column.rawColumnKey,
      width: Math.ceil(newWidth),
    });
  };

  const editColsWidth = () => {
    colsWidth.forEach((item) => {
      if (item.name === column.rawColumnKey) {
        item.width = Math.ceil(newWidth);
      }
    });
  };

  if (colsWidth.length > 0) {
    const isColWidth = colsWidth.some(
      (item) => item.name === column.rawColumnKey
    );

    isColWidth ? editColsWidth() : pushedColsWidth();
  } else {
    pushedColsWidth();
  }

  changeColsWidthUserOption(colsWidth, colStrutWidth);
};

const changePopoverTypeTriggerTime = (
  type: TooltipTriggerType,
  time: number
): any => {
  setTimeout(() => {
    popoverTypeTrigger.value = type;
  }, time);
};

const hasExpandColumn = (): boolean =>
  router.currentRoute.value.path === "/catalog-vehicles/wheels";

const getTableRowClass = ({ row }: { row: any }): string =>
  selectionIds.value.includes(row.id) ? "is-active" : "";

const sortHandler = async ({ prop, order }: Sort) => {
  changePopoverTypeTriggerTime("click", 50);
  if (prop === null) {
    prop = sortRule.value.prop;
    order =
      sortRule.value.order === SortOrderType.desc
        ? SortOrderType.asc
        : SortOrderType.desc;
  }
  setSortProp(prop || "id");
  setSortOrder(order || SortOrderType.desc);
};

watch(props.dataList, () => {
  changePopoverTypeTriggerTime("hover", 1000);
});

const tireSizingSystemText = (string: string) => d(string.replaceAll("-", "_"));

const selectOption = (col: TableColumn, row: Record<string, any>) =>
  col.selectOptions.find((item: any) => item.value === row[col.key])?.label ||
  row[col.key] ||
  "-";

onMounted(() => {
  changePopoverTypeTriggerTime("hover", 1000);

  if (isColStrutWidth.value) {
    strutWidth.value = userOptions.value[path.value]?.colStrutWidth?.width || 0;
  }
});

const dataListTable = computed((): DataList[] => {
  if (props.dataList.loading) {
    return [];
  }

  const data = (props.dataList.value || []).map(
    (item: TableColumn): TableColumn => {
      if (item.relationships) {
        return {
          ...item.attributes,
          id: item.id,
          typeItem: item.type,
          relationships: item.relationships,
          ...(item.count_relationships || {}),
        };
      }

      return {
        ...item.attributes,
        id: item.id,
        typeItem: item.type,
        ...(item.count_relationships || {}),
      };
    }
  );

  return data;
});

let needToClearSelection = false;

const handleSelectAll = () => {
  if (selectionIds?.value?.length === maxSelection) {
    needToClearSelection = true;
  }
};

const selectionIds = ref<string[]>([]);

watch(selection, (newSelection) => {
  newSelection = toRaw(newSelection);
  newSelection = newSelection.map((item: any) => toRaw(item));

  if (tableInstance.value) {
    if (!newSelection.length || needToClearSelection) {
      tableInstance.value.clearSelection();
      needToClearSelection = false;
    } else {
      dataListTable.value.forEach((item: any) => {
        tableInstance.value.toggleRowSelection(
          item,
          newSelection.includes(item)
        );
      });
    }
  }

  selectionIds.value = [];
  newSelection.forEach((item: any) => selectionIds.value.push(item.id));
});
</script>

<style lang="scss" module>
.table-loader {
  min-height: 50%;
}

.table-wrapper {
  height: 100%;
  max-height: 100%;
  padding-bottom: 16px;

  @media (max-width: 640px) {
    padding: 0;
  }

  :global {
    .el-table {
      overflow: initial;

      &.hide-burger {
        tr {
          &.el-table__row {
            padding: 6px 0;
          }
        }
      }

      &:before {
        height: 0;
      }

      thead {
        height: 48px;
        border-bottom: 1px dashed var(--el-border-color);
        font-size: 14px;
        color: var(--el-text-color-placeholder);
        font-weight: 700;
        line-height: 22px;
        margin-left: 24px;
        margin-right: 24px;

        @media (max-width: 640px) {
          height: auto;
          margin: 0;
        }

        &.is-group th.el-table__cell {
          background: none;
        }

        tr {
          @media (max-width: 640px) {
            padding: 16px 0;
          }

          &:not(:first-child) {
            @media (max-width: 640px) {
              display: none;
            }
          }
        }
      }

      th {
        &.el-table__cell {
          background-color: transparent;
          padding: 0;

          &.is-leaf {
            border-bottom: 1px dashed var(--lt-color-border-light);
          }

          &.is-group {
            background: #bec2c7 !important;
            color: var(--el-text-color-primary-dark);
          }

          & > .cell {
            word-break: normal;
            white-space: nowrap;
          }

          &.el-table-fixed-column--right {
            background-color: var(--el-color-white);
          }
        }

        &:not(.bulk) {
          @media (max-width: 640px) {
            display: none;
          }
        }
      }

      td {
        @media (max-width: 640px) {
          display: flex;
        }

        &.el-table__cell {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          color: var(--el-text-color-regular);
          border-bottom: 1px dashed var(--lt-color-border-light);
          padding: 6px 0;

          @media (max-width: 640px) {
            text-align: left;
            padding: 0 16px 10px !important;
          }

          &.transparent-lighter-filled {
            opacity: 0.6;
          }

          &.minimized.active {
            .cell {
              justify-content: center;
              display: flex;
              padding: 0;
            }
          }

          &.el-table__cell--actions {
            padding: 0;

            button {
              width: 28px;
            }

            & > .cell {
              padding: 0 4px;
            }
          }

          &.amount .cell > a {
            width: auto;
            display: inline-flex;
            color: var(--el-text-color-regular);
            text-decoration: none;

            &:hover > * {
              background-color: var(--el-color-white);
            }

            & > :nth-child(1) {
              border: 1px solid var(--el-border-color);
              border-right: none;
              border-radius: 100px 0 0 100px;
              width: 16px;
            }

            & > :nth-child(2) {
              border-top: 1px solid var(--el-border-color);
              border-bottom: 1px solid var(--el-border-color);
            }

            & > :nth-child(3) {
              border: 1px solid var(--el-border-color);
              border-left: none;
              border-radius: 0 100px 100px 0;
              padding-left: 7px;
              padding-right: 15px;
            }

            .el-icon {
              vertical-align: text-top;
              padding-top: 1px;
            }
          }

          &.activity .cell {
            text-overflow: initial;
          }

          &.el-table__expanded-cell {
            border-bottom: 1px dashed var(--el-border-color);
            padding: 0 !important;

            .el-table__row {
              @media (max-width: 640px) {
                padding: 0;
              }

              .minimized {
                @media (max-width: 640px) {
                  display: none;
                }
              }
            }

            .empty-col {
              @media (max-width: 640px) {
                display: none;
              }
            }

            .el-table__cell {
              padding: 0;
            }
          }

          & > .cell {
            word-break: break-word;

            .dot {
              border: 4px solid;
              border-radius: 10px;
            }

            .inactive {
              color: var(--el-text-color-disabled);
              border-color: var(--el-text-color-disabled);
            }

            .success {
              color: var(--el-color-success);
              border-color: var(--el-color-success);
            }

            .fail {
              border-color: var(--el-color-error);
              color: var(--el-color-error);
            }

            .spaced {
              margin-left: 5px;
            }
          }

          .el-table__cell .el-table__cell {
            @media (max-width: 640px) {
              padding: 6px 0 !important;
            }
          }
        }

        &.bulk {
          @media (max-width: 640px) {
            height: 24px;
            position: absolute;
            left: 16px;
            top: 16px;
            padding: 0 !important;
          }

          .cell {
            @media (max-width: 640px) {
              height: 24px;
            }
          }
        }

        &.table-burger {
          @media (max-width: 640px) {
            position: absolute;
            right: 0;
            top: 16px;
          }
        }

        &.minimized.active {
          @media (max-width: 640px) {
            position: absolute;
            left: 54px;
            top: 23px;
            padding: 0 !important;
          }
        }

        &.el-table__expand-column {
          @media (max-width: 640px) {
            width: 68px;
            display: flex;
            justify-content: center;
            z-index: 2;
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }

        &.el-table-fixed-column--right {
          @media (max-width: 640px) {
            position: static !important;
            padding: 0 !important;
          }

          .cell {
            & > div:first-child {
              @media (max-width: 640px) {
                display: none;
              }
            }
          }
        }

        .cell {
          @media (max-width: 640px) {
            position: static;
            padding: 0 !important;
          }
        }
      }

      .sort-caret {
        border-width: 4px;

        &.ascending {
          top: -3px;
        }

        &.descending {
          bottom: -1px;
        }
      }
    }

    table {
      @media (max-width: 640px) {
        max-width: 100%;
        display: block;
      }
    }

    tbody {
      @media (max-width: 640px) {
        display: flex;
        flex-direction: column;
      }

      tr {
        @media (max-width: 640px) {
          border-bottom: 1px dashed var(--el-border-color);
        }
      }
    }

    tr {
      @media (max-width: 640px) {
        height: auto;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 8px 0 0;
      }

      &.el-table__row {
        @media (max-width: 640px) {
          height: auto;
          padding: 54px 0 6px;
        }
      }
    }

    .el-table__row {
      height: 60.5px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: var(--el-text-color-primary);
      border-bottom: 1px dashed var(--el-border-color);

      &.el-table__row--active {
        background-color: var(--el-color-primary-light-9);
      }

      &:hover,
      &.hover-row {
        background-color: var(--el-fill-color-lighter) !important;

        @media (max-width: 640px) {
          background-color: transparent !important;
        }

        .el-table__cell {
          background-color: var(--el-fill-color-lighter) !important;

          @media (max-width: 640px) {
            background-color: transparent !important;
          }
        }
      }

      &.is-active {
        background-color: var(--el-color-primary-light-9);
      }
    }

    .el-table__column {
      &-resize-proxy {
        &::after {
          content: "";
          width: 3px;
          height: 27px;
          background: #008859;
          border-radius: 1px;
          z-index: 10;
          position: absolute;
          top: 10px;
          left: -2px;
        }
      }
    }

    .el-table__cell {
      & > .cell:has(> .el-dropdown) {
        text-overflow: initial;
      }

      &.bulk {
        .cell {
          display: flex;
          align-items: center;
          padding: 12px 16px;

          .el-checkbox {
            height: fit-content;
          }

          .is-indeterminate .el-checkbox__inner::before {
            border-bottom: 2px solid var(--el-color-white);
            top: 9px;
          }

          .el-checkbox__inner {
            height: 24px;
            width: 24px;

            &:after {
              border-bottom: 2px solid var(--el-color-white);
              border-right: 2px solid var(--el-color-white);
              height: 12px;
              left: 7px;
              top: 2px;
              width: 6px;
            }
          }
        }
      }
    }

    .el-table__fixed-right::before {
      height: 0;
    }

    .el-table__body {
      border-collapse: collapse;
      overflow-x: auto;
      margin-left: 24px;
      margin-right: 24px;

      @media (max-width: 640px) {
        margin: 0;
      }
    }

    .el-button--small {
      padding: 6px 7px;
      border-radius: calc(var(--el-border-radius-base) - 1px);
      width: 36px;
      height: 24px;
      min-height: 24px;

      &:hover,
      &:focus {
        border-color: var(--el-color-primary);
      }
    }

    .el-table--border .el-table__cell {
      border-right: none;
    }

    .el-table__header-wrapper .el-table__cell.active-resize:hover {
      border-right: 1px solid #e1e6e4 !important;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: 11px;
        right: -2px;

        width: 3px;
        height: 27px;

        background: #008859;
        border-radius: 1px;

        z-index: 10 !important;
      }
    }

    .noclick {
      border-right: 1px solid #e1e6e4 !important;
      position: relative;
      cursor: ew-resize !important;

      &::after {
        content: "";
        position: absolute;
        bottom: 11px;
        right: -2px;

        width: 3px;
        height: 27px;

        background: #008859;
        border-radius: 1px;

        z-index: 10 !important;
        opacity: 0;
      }
    }

    .el-table--border,
    .el-table--group {
      border: none;
    }

    .no-expand .el-table__expand-icon {
      display: none;
      pointer-events: none;
    }

    .expand-col.is-group > .cell {
      padding: 0 !important;
    }

    .expand-col .el-table__body,
    .el-table__expanded-cell .el-table__body {
      margin-left: 0;
      margin-right: 0;
    }

    .el-table__expanded-cell .el-table__row {
      border-bottom: 0;
    }

    .el-button.is-circle {
      width: 40px;
    }

    .el-table__header {
      margin-left: 24px;
      margin-right: 24px;
      border-bottom: 1px dashed var(--el-border-color);

      @media (max-width: 640px) {
        background: #fff;
        margin: 0;
      }
    }

    .el-table--border .el-table__inner-wrapper::after,
    .el-table--border::after,
    .el-table--border::before,
    .el-table__inner-wrapper::before {
      background-color: transparent;
    }

    .el-scrollbar__view {
      @media (max-width: 640px) {
        max-width: 100%;
      }
    }

    .fixed-class {
      @media (max-width: 640px) {
        position: static;
      }
    }
  }

  .expand-table :global .el-table__inner-wrapper::before {
    background-color: transparent;
  }
}

.custom-style-el-popover {
  text-align: center !important;
  width: 100% !important;
  max-width: 220px !important;
}

:global {
  .el-popover.el-popper {
    word-break: break-word !important;
    min-width: 30px !important;
  }
}

.col-burger {
  @media (max-width: 640px) {
    position: absolute !important;
    right: 0;
    top: 16px;
  }

  :global {
    .cell {
      padding: 0 10px;
    }
  }
}
</style>
